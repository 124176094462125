import React, { Component } from 'react'
import ParticlesBg from 'particles-bg'
import { Fade } from 'react-awesome-reveal'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSection: '#home' // Default aktif di #home
    }
  }

  handleSetActive = section => {
    this.setState({ activeSection: section })
  }
  render () {
    if (!this.props.data) return null
    const project = this.props.data.project
    const github = this.props.data.github
    const name = this.props.data.name
    const description = this.props.data.description
    const { activeSection } = this.state

    return (
      <header id='home'>
        <ParticlesBg type='circle' bg={true} />
        <nav id='nav-wrap'>
          <a className='mobile-btn' href='#nav-wrap' title='Show navigation'>
            Show navigation
          </a>
          <a className='mobile-btn' href='#home' title='Hide navigation'>
            Hide navigation
          </a>

          <ul id='nav' className='nav'>
            {[
              { href: '#home', label: 'Home' },
              { href: '#about', label: 'About' },
              { href: '#resume', label: 'Resume' },
              { href: '#portfolio', label: 'Works' },
              { href: '#contact', label: 'Contact' }
            ].map(item => (
              <li
                key={item.href}
                className={activeSection === item.href ? 'current' : ''}
              >
                <a
                  className='smoothscroll'
                  href={item.href}
                  onClick={() => this.handleSetActive(item.href)}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <div className='row banner'>
          <div className='banner-text'>
            <Fade bottom>
              <h1 className='responsive-headline'>{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
            <Fade bottom duration={2000}>
              <ul className='social'>
                <a href={project} className='button btn project-btn'>
                  <i className='fa fa-book'></i>Blog
                </a>
                <a href={github} className='button btn github-btn'>
                  <i class='fa-brands fa-github'></i> Github
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className='scrolldown'>
          <a className='smoothscroll' href='#about'>
            <i className='icon-down-circle'></i>
          </a>
        </p>
      </header>
    )
  }
}

export default Header
