import { Component } from 'react'
import Slide from 'react-awesome-reveal'

class Resume extends Component {
  getRandomColor () {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  render () {
    if (!this.props.data) return null

    const skillmessage = this.props.data.skillmessage
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className='info'>
            {education.degree} <span>&bull;</span>
            <em className='date'>{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      )
    })

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className='info'>
            {work.title}
            <span>&bull;</span> <em className='date'>{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      )
    })

    const skills = this.props.data.skills.map(skills => {
      const backgroundColor = this.getRandomColor()
      const className = 'bar-expand ' + skills.name.toLowerCase()
      const width = skills.level

      return (
        <div
          key={skills.name}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            background: '#f8f9fa',
            padding: '10px',
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)'
          }}
        >
          <i
            style={{ fontSize: '24px', color: skills.color }}
            className={skills.icon}
          ></i>

          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {skills.name}
          </span>
        </div>
      )
    })

    return (
      <section id='resume'>
        <Slide left duration={1300}>
          <div className='row education'>
            <div className='three columns header-col'>
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              <div className='row item'>
                <div className='twelve columns'>{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className='row work'>
            <div className='three columns header-col'>
              <h1>
                <span>Work</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              <div className='row item'>
                <div className='twelve columns'>{work}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className='row skill'>
            <div className='three columns header-col'>
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              <p>{skillmessage}</p>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
                  gap: '15px',
                  padding: '20px'
                }}
              >
                {skills}
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', margin: 'auto' }} className=''>
            <p style={{ textAlign: 'center' }}>
              <a
                href='/certificate'
                style={{ color: 'white' }}
                className='button'
              >
                CHECK CERTIFICATIONS{' '}
              </a>
            </p>
          </div>
        </Slide>
      </section>
    )
  }
}

export default Resume
